import { template as template_45421eaff99b43c49468acdc10a7b05d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_45421eaff99b43c49468acdc10a7b05d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
