import { template as template_2e0dfc7e0bbf4422a4d1a0abcef40043 } from "@ember/template-compiler";
const FKLabel = template_2e0dfc7e0bbf4422a4d1a0abcef40043(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
