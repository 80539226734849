import { template as template_ff5d1cf3cfed4b6897bf843f44190530 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_ff5d1cf3cfed4b6897bf843f44190530(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
