import { template as template_f8a3ce1e1e4f44fd966d7d3d20b0c654 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_f8a3ce1e1e4f44fd966d7d3d20b0c654(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
