import { template as template_829feac5f2184e71933c7b64d283b43c } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_829feac5f2184e71933c7b64d283b43c(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
